.button-bottom-right{
    position: fixed !important;
    right: 15px;
    bottom: 15px;
    background-color: green !important;
    border: green !important;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}

    .button-bottom-right:hover{
        background-color: darkgreen !important;
        border:darkgreen !important;
    }

    .button-bottom-right:active{
        background-color: darkgreen !important;
        border: darkgreen !important;
    }
.button-bottom-left{
    position: fixed !important;
    left: 15px;
    bottom: 15px;
    background-color: #f44336 !important;
    border: #f44336 !important;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}

.button-bottom-left:hover{
    background-color: #ce4034 !important;
    border: #ce4034 !important;
}

.button-bottom-left:active{
    background-color: #a1342a !important;
    border: #a1342a !important;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 60px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
}

.modal-backdrop {
    opacity: 0.5;
}

.modal.fade.in {
    opacity: 1;
}

.modal.fade.in .modal-dialog {
    transform: translate(0,0);
}

.my-node-enter {
    opacity: 0;
}
.my-node-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.my-node-exit {
    opacity: 1;
}
.my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}
